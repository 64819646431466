/* Strings ----------------------------------------------------------------- */
$staging_base: "/";
$template_dir: $staging_base;
$img_dir:      "../../assets/images/";
$font_dir:     "../../assets/fonts/";

/* Colors ------------------------------------------------------------------ */
$color_pink:     #e14e57;
$color_fog:      #f7f7f7;
$color_black:    #000000;
$color_white:    #ffffff;
$color_grey:     #7a7777;
$color_cream:    #f4f2e8;
$color_cream_light: #f7f5ee;

/* Fonts ------------------------------------------------------------------- */
$font_sans_serif: "sofia-pro";
$font_icon:       "fontello";

/* SVGs ------------------------------------------------------ */
$svg_blue_wave_1: '<svg width="1400" height="791" viewBox="0 0 1400 791" xmlns="http://www.w3.org/2000/svg"><g fill="#001CC1" fill-rule="evenodd"><path d="M0 481h1400v310H0z"/><path d="M1400 70.923c-148.955 40.434-275.321 79.175-525.886 64.085C623.55 119.918 280.75-37.232 0 8.222v491.78h1400V70.923z" fill-rule="nonzero"/></g></svg>';
$svg_blue_wave_2: '<svg xmlns="http://www.w3.org/2000/svg" width="1400" height="791" viewBox="0 0 1400 791"><g id="Desktop" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g id="hollister-staffing" fill="#001CC1" transform="translate(0 -2267)"><g id="blue-wave" transform="translate(0 2267)"><path id="blue-strip" d="M0 481h1400v310H0z"/><g id="blue-arc" fill-rule="nonzero"><g id="wave-1" transform="matrix(-1 0 0 1 1400 0)"><path id="Combined-Shape" d="M0 70.923c148.955 40.434 275.321 79.175 525.886 64.085C776.45 119.918 1119.25-37.232 1400 8.222v491.78H0V70.923Z"/></g></g></g></g></g></svg>';


/* Weights ----------------------------------------------------------------- */
$weight_medium: 500;
$weight_bold:   700;
//$weight_black:  900;

/* Breakpoints ------------------------------------------------------------- */
$bp_xxxl:   2000px;
$bp_xxl:    1900px;
$bp_xl:     1700px;
$bp_l:      1500px;
$bp_ml:     1350px;
$bp_medium: 1000px;
$bp_ms:     800px;
$bp_small:  700px;
$bp_sm:     520px;
$bp_xs:     480px;

$bp_text_m: 1040px;

/* Grid -------------------------------------------------------------------- */
$max_width:         1440px;
$max_width_content: 1400px;

/* Gutters ------------------------------------------------------ */
$gutter_width: 30px;
$gutter_width_small: 15px;

/* Z ----------------------------------------------------------------------- */
$z_0: 0;
$z_1: 1;
$z_2: 2;
$z_3: 3;
$z_4: 4;
