.grid {
    &--suhri {
        &--top {
            z-index: 1;
            @media screen and (min-width: $bp_small) {
                display: grid;
                // 250px also works gor the middle row
                grid-template-rows: 1fr 0.5fr 1fr;
                grid-template-columns: 1fr 1fr;
            }
            .grid__image-top {
                @media screen and (min-width: $bp_small) {
                    grid-row: 1/3;
                    grid-column: 1/2;
                }
            }
            .grid__image-bottom {
                @media screen and (min-width: $bp_small) {
                    grid-row: 2/4;
                    grid-column: 2/3;
                }
            }
            .cool-pic {
                @media screen and (max-width: $bp_small - 1) {
                    picture {
                        z-index: 1;
                    }
                }
            }
        }
        &--bottom {
            z-index: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            display: none;
            @media screen and (min-width: $bp_small) {
                display: grid;
                grid-template-rows: 0.5fr 1fr 0.7fr 1.25fr;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
            .grid__background-top {
                @media screen and (min-width: $bp_small) {
                    grid-row: 2/3;
                    grid-column: 3/5;
                }
            }
            .grid__background-bottom {
                @media screen and (min-width: $bp_small) {
                    grid-row: 4/5;
                    grid-column: 2/5;
                }
            }
        }
    }
    &--hollister {
        @media screen and (min-width: $bp_small) {
            margin-top: 90px;
        }
        > * {
            background-image: svg-url($svg_blue_wave_2);
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: $bp_small - 1) {
                background-position: -1341px 150px;
            }
        }
    }
}

.grid-item--foreground--bg {
    position: absolute;
    z-index: 0;
    inset: 0;
    margin-left: -$gutter_width;
    margin-right: -$gutter_width;
    width: calc(100% + ($gutter_width * 2));
    display: flex;
    justify-content: center;
    align-items: center;
    > * {

    }
    @media screen and (min-width: $bp_small) {
        display: none;
    }
}

.grid-item {
    &--foreground {
        z-index: 1;
    }
    &--background {
        z-index: 0;
    }
}

.section {
    &--p2p {
        .layers {
            .cool-pic {
                z-index: 1;
            }
            .bg {
                position: absolute;
                display: block;
                z-index: 0;
                margin-left: -$gutter_width;
                margin-right: -$gutter_width;
                width: calc(100% + ($gutter_width * 2));
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &--left {
                .bg {
                    width: 659.2px;
                    height: 668px;
                    right: 63px;
                    top: -39px;
                    @media screen and (max-width: $bp_small - 1) {
                        right: 151px;
                    }
                }
            }
            &--right {
                .bg {
                    width: 551px;
                    height: 515px;
                    left: 278px;
                    top: 136px;
                    @media screen and (max-width: $bp_small - 1) {
                        left: 383px;
                    }
                }
            }
        }
    }
}

.shave-arrow {
    position: absolute;
    top: -45.5px;
    left: calc(50% - 45.5px);
}