.template-project,
.template-home {
  footer {
    display:flex;
  }
}

footer {
  background-color: $color_cream;
  z-index: $z_0;
  display: none;
  justify-content: flex-start;
  padding-right: 30px;
  padding-left: 30px;
  @media screen and (min-width: $bp_small) {
    padding-right: 50px;
    padding-left: 50px;
    justify-content: flex-end;
  }
  .footer-inner  {
    max-width:382px;
    width: 100%;
    text-align: left;
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    padding-top: 30px;
    padding-bottom: 80px;
    .watermark {
      position: relative;
      display: inline-block;
      line-height: 0;
      svg {
        fill: $color_black;
        width: 70px;
        height: 51.3px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      margin: 15px 0 0 0;
      padding: 0;
      list-style-type: none;
      li + li {
        margin-top: 20px;
      }
    }
  }
  // Footer typography
  font-family: $font_sans_serif;
  font-weight: $weight_medium;
  @include font-size(16);
  h3 {
    font-family: $font_sans_serif;
    font-weight: $weight_bold;
    @include font-size(21);
  }
  li {
    line-height: 12px;
  }
}

// Sneaky footer
body {
  .template-contact {
    padding-bottom:100px;
  }
  .template-project {
    padding-bottom:300px;
  }
  footer {
    position:fixed;
    width: 100%;
    bottom:0;
    left:0;
  }
}

.template-home {
  footer {
    transition: opacity 0.15s;
    opacity: 0;
  }
  &.loaded {
    padding-bottom:300px;
    footer {
      opacity: 1;
    }
  }
}
