

.template-home {
  h1 {
    font-family: $font_sans_serif;
    font-weight: $weight_medium;
    @include font(23, 35);
  }
}
h2 {
  font-family: $font_sans_serif;
  font-weight: $weight_bold;
  @include font(18, 24);
  @media screen and (min-width:$bp_small) {
    @include font(24, 30);
  }
}
h3 {
  font-family: $font_sans_serif;
  font-weight: $weight_bold;
  @include font(18, 28);
  letter-spacing: 0.02rem;
}

main {
  ul {
    li {
      font-weight: $weight_medium;
    }
  }
}

p {
  @include font(18, 28);
  font-family: $font_sans_serif;
  font-weight: $weight_medium;
  letter-spacing: 0.02rem;
  small {
    @include font(16, 24);
  }
  strong {
    font-weight: $weight_bold;
  }
}

h3, p {
  a {
    color: $color_pink;
    &:hover,
    &:active {
      color: $color_pink;
      border-bottom:3px solid $color_pink;
    }
  }
}

.header {
  h1 {
    @include font-size(16);
    line-height:1.5;
    @media screen and (min-width:$bp_sm) {
      @include font-size(23);
      line-height:1.52;
    }
  }
}

.light-bg {
  h1, h2, h3, p, a, div {
    color: $color_grey !important;
  }
}
