$block_height: 400px;
$block_left_width: 630px;
$block_right_width: 550px;
$block_right_width_min: 270px;
$gutter: 40px;

main:not(.home):not(#hometwo) {
  padding-top: 130px;
  @media screen and (min-width: $bp_small) {
    padding-top:185px;
  }
}

// top padding for contact / 404 template
main.contact {
  @media screen and (max-width: $bp_small - 1) {
    padding-top: 100px !important;
  }
}

$layer_picture:         $z_1;
$layer_picture_loading: $z_1 + 1;
$layer_hover_block:     $z_1 + 2;
$layer_content:         $z_1 + 3;

// change this to a header element later
.header {
  background-color: $color_cream;
  padding:110px 35px 45px;
  @media screen and (min-width:$bp_sm) {
    padding:110px 0 40px;
  }
  a {
    position: absolute;
    top: 15px;
    display: inline-block;
    &.contact {
      transition: left 0.15s;
      left: 14px;
      span {
        display: none;
      }
      @media screen and (min-width: $bp_small) {
        left: 30px;
        span {
          display: inline;
        }
      }
    }
    &.logo-small {
      right: 30px;
      display:none;
      @media screen and (min-width: $bp_sm) {
        display:block;
      }
      svg {
        width: 76.6px;
        height: 56px;
      }
    }
  }
  .logo-main {
    text-align: center;
    display: flex;
    flex-direction: column;
    > * {
      display: inline-block;
      margin: 0 auto;
    }
    svg {
      fill: $color_pink;
      width:121px;
      height:42px;
      @media screen and (min-width: $bp_sm) {
        width: 344px;
        height: 126px;
      }
    }
    .full-logo {
      margin-top: 36px;
      svg {
        width:224px;
        height:14px;
        g {
          fill:$color_black;
        }
      }
      @media screen and (min-width:$bp_sm) {
        display: none;
      }
    }
    h1 {
      max-width:250px;
      margin-top:20px;
      @media screen and (min-width: $bp_sm) {
        max-width:480px;
      }
    }
  }
  .view-our-work {
    max-width:480px;
    text-align: center;
    margin: 56px auto 0;
    > * {
      cursor: pointer;
    }
    .down-icon {
      margin-top: 13px;
      //margin-bottom: -2px;
      line-height: 0;
      svg {
        transform: rotate(90deg);
        width: 13.5px;
        height: 13.5px;
      }
    }
  }
}

main#hometwo {
  .boxes {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @media screen and (min-width: $bp_sm) {
      flex-direction: row;
    }
  }


  section {
    overflow: hidden;
    width: 100%;
    @media screen and (min-width: $bp_sm) {
      width: 50%;
    }
    @media screen and (min-width: $bp_ms) {
      width: 33.333333%;
    }
    @media screen and (min-width: 1300px) {
      width: 25%;
    }
    &:nth-child(9) {
      @media screen and (min-width: $bp_sm) and (max-width: $bp_ms - 1) {
        //display: none;
      }
      @media screen and (min-width: 1300px) {
        //display: none;
      }
    }
    &.extra {
      background-color: transparent !important;
      display: none;
    }
    &.parting-advice {
      background-color:$color_cream;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      @media screen and (min-width: $bp_sm) {
        width: 50%;
        //display: none;
      }
      @media screen and (min-width: $bp_ms) {
        display: none;
        //width: 100%;
      }
      @media screen and (min-width: 1300px) {
        display:flex;
        width: 25%;
      }
      svg {
        fill:$color_pink;
        width: 79px;
        height:28px;
      }
      .content-positioner {
        position:absolute;
        top:0; left:0; bottom:0; right:0;
        width:100%;
        height:100%;
        display: flex;
        .content {
          padding-left:15px;
          padding-right:15px;
          width: 100%;
          display:flex;
          flex-direction: column;
          align-items:stretch;
          justify-content:center;
        }
      }
      .text-content {
        width: 100%;
        text-align: center;
        margin-bottom:30px;
      }
      .footer-logo {
        position:absolute;
        bottom: 30px;
        width: 100%;
        text-align:center;
        svg {
          display: inline-block;
          margin:0 auto;
        }
      }
    }
    &.parting-advice-left {
      @media screen and (min-width: 1300px) {
        display:flex !important;
        width: 25%;
      }
    }
    &.parting-advice-right {
      @media screen and (min-width: 1300px) {
        display:flex !important;
        width: 25%;
      }
    }

    // Hover overlay for images
    a:hover,
    a:active {
      .hover-block {
        transition: opacity 0.15s;
        opacity: 1;
      }
    }


    .hover-block {
      content:"";
      display: block;
      position: absolute;
      width: 100%;
      height:100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index:$layer_hover_block;
      background-color:transparentize($color_black, 0.6);
    }

    picture, img {
      z-index: $layer_picture;
      opacity: 1;
    }

    &.loading {
      picture {
        opacity: 0;
      }
    }

    .loading-image {
      transition: all 1s;
      opacity: 1;
      z-index: $layer_picture_loading;
      display: block;
      filter: blur(20px);
      position: absolute;
      background-size:cover;
      width: 120%;
      height:120%;
      left:-10%;
      top:-10%;
      &.loaded {
        opacity: 0;
        width: 100%;
        height:100%;
        left:0;
        top:0;
      }
    }


    a {
      .inner {
        position: relative;
      }
    }

    .content-info {
      z-index: $layer_content;
      position: absolute;
      bottom: 30px;
      left: 30px;
      padding-right: 30px;
    }
  }
}



// Remove background color from 3-col panel placeholders
// Just make all pictures fade in
.panel {
  .cool-pic {
    picture {
      transition: opacity 0.15s linear;
      opacity: 0;
      &.loaded {
        opacity: 1;
      }
    }
  }
  .cool-pic.loading {
    .picture-cover {
      background-color: transparent !important;
      &:before {
        // background-color: transparent !important;
      }
    }
  }
}

.cool-pic {
  display: flex;
  //flex: 1 !important;
  //transition: $transition;
  background-color:transparent;
  &.loading {
    .picture-cover {
      background-color: $color_cream;
      z-index: $layer_picture_loading - 1;
      &:before {
        // Uncomment below to add the color difference above loading images
        //background-color: transparentize($color_white, 0.6);
        content:"";
        display: block;
        width: 100%;
        height:100%;
        left: 0;
        right: 0;
      }
    }
  }
  .picture-cover {
    //transition: $transition;
    width: 100%;
    height:100%;
    left:0;
    top:0;
    display:block;
    position: absolute;

    z-index: 0;
    &:before {
      //transition: $transition;

    }
    &.loaded {
      background-color: transparent;
      &:before {
        background-color: transparent;
      }
    }
  }
}

// ----------------------------------------------------------------------------
// CONTACT: TEMPLATE;
// ----------------------------------------------------------------------------

main.contact {
  .container {
    padding-left:15px;
    padding-right:15px;
    @media screen and (min-width:$bp_small) {
      padding-left:30px;
      padding-right:30px;
    }
  }
  .logos {
    margin-bottom: 30px;
    .project-logo-large {
      display: none;
      @media screen and (min-width:$bp_small) {
        display: block;
      }
      svg {
        height: 126px;
        width: 344px;
        fill: $color_pink;
      }
    }
    .project-logo-small {
      display: block;
      text-align: center;
      @media screen and (min-width:$bp_small) {
        display: none;
      }
      svg {
        margin: 0 auto;
        height: 14px;
        width: 244px;
        fill: $color_black;
        g {
          fill: $color_black;
        }
      }
    }
  }
  .content {
    .description {
      @media screen and (max-width:$bp_small - 1) {
        display: none;
      }
      > * + * {
        margin-top: 30px;
      }
    }
    picture {
      margin-top: 50px;
    }
    ul {
      list-style-type: none;
      > li + li {
        margin-top: 6px;
      }
      li {
        padding-left: 14px;
        &:before {

          position: absolute;
          left: 0;
          content: "+";
        }
      }
    }
    .col:first-child {
      h3 {
        margin-top:30px;
        &:first-of-type {
          @media screen and (max-width:$bp_small - 1) {
            margin-top: 0;
          }
        }
      }
    }
    .col:last-of-type {
      h3 {
        margin-top:30px;
      }
      h3:first-child {

        @media screen and (min-width: $bp_small) {
          margin-top: 0;
        }
      }
      p:last-of-type {
        margin-top:30px;
      }
    }
  }
}

.block--extra-margin {
  margin-top: 30px;
}
.block--extra-margin-mobile {
  @media screen and (max-width: $bp_small - 1) {
    margin-top: 30px !important;
  }
}

// ----------------------------------------------------------------------------
// Panel Sliding
// ----------------------------------------------------------------------------

.template-project {
  main {
    section:not(.dont-move) {
      picture:not(.background), svg {
        transition: all 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translateY(100px);
        &.moved {
          transform: translateY(0px);
        }
      }
    }
    .frame {
      transition: all 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transform: translateY(100px);
      opacity: 0;
      &.moved {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
}

.dont-move {
  svg, picture {
    transform: translateY(0px) !important;
  }
}

// ----------------------------------------------------------------------------
// About styling
// ----------------------------------------------------------------------------

body.about {
  canvas {
    //top: 72px !important;
    //left: 50%;
    //transform: translateX(-50.3%);
  }
}

.slides {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z_1;
}

.slide {
  //background-color: transparentize(#ff00cc, 0.5);
  width: 100vw;
  height: 100vh;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //padding: 25px;
}

.slide__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50.3%) translateY(-52%);

  text-align: center;
  padding: 25px;
  background-color: white;
  max-width: 450px;
}

.slide__content__text {
  font-size: 3.5vmin;
}

// ----------------------------------------------------------------------------
// Absolute slides
// ----------------------------------------------------------------------------

/*
.slides--absolute {
  .slide {

  }
  .slide__content {
    position: relative;
    top: inherit;
    left: inherit;
    transform: inherit;
    max-width: 400px;
    margin: 300px auto 0;
  }
  .slide__content__text {
    font-size: 40px;
  }
}
*/

canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


/* Heade panel ------------------------------------------------------ */

.panel {
  &--header {
    .panel--header__bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      min-height: 255px;
      max-height: 500px;
      height: 100%;
      max-width: none !important;
      * {
        max-width: none !important;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .panel--header__content {
      z-index: 1;
      @media screen and (max-width: $bp_small - 1) {
        > *:first-child {
          margin-top: 20%;
        }
      }
      @media screen and (min-width: $bp_small) {
        > * {
          margin-top: 25%;
        }
      }
    }
  }
}