@use 'sass:math';

// Responsive image mixin
@mixin hr-image($image, $width, $height) {
  @media print,
         (-webkit-min-device-pixel-ratio: 1.25),
         (-o-min-device-pixel-ratio: 5/4),
         (min-resolution: 120dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

// Quick REMs
@mixin font-size($size) {
  $remVal: ($size * .1);
  font-size: $remVal + rem;
  font-size: $size + px;
}

// REMs and line height
@mixin font($font-size, $line-height: $font-size) {
  $remVal: ($font-size * .1);
  font-size: $remVal + rem;
  font-size: $font-size + px;
  line-height: math.div($line-height, $font-size);
  
}

// Intrinsic mixin minor
@mixin aspect($ratio: 1 1) {
	padding-top: percentage(nth($ratio, 2) / nth($ratio, 1));
}

// Intrinsic mixin extreme
@mixin fluid-aspect($ratio: 1 1, $selector: "> :first-child") {
	$selector: unquote($selector);

	padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
	position: relative;

	#{$selector} {
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

// Round a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @param  {String} [$mode:round] (round|ceil|floor) How to round a number
// @return {Number} A rounded number
// @example
//     decimal-round(0.333)    => 0
//     decimal-round(0.333, 1) => 0.3
//     decimal-round(0.333, 2) => 0.33
//     decimal-round(0.666)    => 1
//     decimal-round(0.666, 1) => 0.7
//     decimal-round(0.666, 2) => 0.67
//
@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode == round {
    @return round($number * $n) / $n;
  } @else if $mode == ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode == floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

// Ceil a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A ceiled number
// @example
//     decimal-ceil(0.333)    => 1
//     decimal-ceil(0.333, 1) => 0.4
//     decimal-ceil(0.333, 2) => 0.34
//     decimal-ceil(0.666)    => 1
//     decimal-ceil(0.666, 1) => 0.7
//     decimal-ceil(0.666, 2) => 0.67
//
@function decimal-ceil($number, $digits: 0) {
  @return decimal-round($number, $digits, ceil);
}

// Floor a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A floored number
// @example
//     decimal-floor(0.333)    => 0
//     decimal-floor(0.333, 1) => 0.3
//     decimal-floor(0.333, 2) => 0.33
//     decimal-floor(0.666)    => 0
//     decimal-floor(0.666, 1) => 0.6
//     decimal-floor(0.666, 2) => 0.66
//
@function decimal-floor($number, $digits: 0) {
  @return decimal-round($number, $digits, floor);
}

// SVG background images
// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-or-null($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}

//
//  Function to replace characters in a string
//
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

@function svg-url($svg) {
  //
  // Chunk up string in order to avoid
  // "SystemStackError: stack level too deep"
  //
  $encoded: "";
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, "<", "%3C");
    $chunk: str-replace($chunk, ">", "%3E");
    $chunk: str-replace($chunk, "&", "%26");
    $chunk: str-replace($chunk, "#", "%23");
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}