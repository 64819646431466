/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  transition:all 0.15s;
  background: $color_pink;
  position: fixed;
  z-index: 2;
  top: 60px;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: none !important;
}
/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: none !important;
}
#nprogress .spinner-icon {
  display: none !important;
}
