.display-none {
  display: none !important;
}
.unpad-bottom {
  padding-bottom: 0 !important;
}
/* fade image in after load */
img[data-sizes="auto"] { display: block; width: 100%; }
.lazyload,
.lazyloading {
    opacity: 0;
    background-color: $color_cream;
}
.lazyloaded {
    opacity: 1;
    transition: opacity 250ms;
  }

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
}
.fade-leave {
}
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter, .fade-leave-active {
}
.fade-leave {

}

.intrinsic {

  // Make sure <picture> wrapper is set to block
  // Max-width is governed by parentNode
  display: block;

  // Intrinsic Ratio Box
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 100%; // Default to square

  // Custom Styling
  background-color: transparentize($color_black, 0.9);

  &.intrinsic-home {
    padding-top: 92.19%;
    &.intrinsic-transparent {
      background-color: transparent;
    }
  }
  .intrinsic-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.lazyloaded {
      background-color:$color_cream;
    }
  }
}

.cool-pic {
  width: 100%;
  overflow:hidden;
  margin-inline: auto;
}

.intrinsic-project {
  // Make sure <picture> wrapper is set to block
  // Max-width is governed by parentNode
  transition: background-color 0.15s;
  //display: block;
  display: flex;

  // Intrinsic Ratio Box
  position: relative;
  height: auto;
  width: 100%;
  object-fit: cover;
  //padding-top: 100%; // Default to square

  // Custom Styling - uncomment this later
  //background-color: transparentize($color_black, 0.95);
  &.loaded {
    background-color:transparent;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.intrinsic-transparent {
    img {
      &.lazyloaded {
        background-color: transparent;
      }
    }
  }
}
