svg {
  outline: 0;
}

.touch {
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

html {

          box-sizing: border-box;
          -webkit-box-sizing: border-box;
             -moz-box-sizing: border-box;
}
* {
          position: relative;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
             -moz-box-sizing: border-box;
}
*:before,
*:after {

          box-sizing: border-box;
          -webkit-box-sizing: border-box;
             -moz-box-sizing: border-box;
}
html, body {
	height: 100%;
}

html {
  overflow-x: hidden;
}

.hide {
  /* Hide stuff without resorting to display:none; */
  visibility: hidden;
  width: 0!important;
  height: 0!important;
  line-height: 0!important;
  padding: 0!important;
  margin: 0!important;
}

/* 2 RESETS
================================================== */

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  /* Read more > http://bit.ly/qh1V0T */
}

html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* The below restores some sensible defaults */

a img {
  border: none;
}

/* Gets rid of IE's blue borders */

/* 2B MEDIA
Based on the Bootstrap toolkit from Twitter http://twitter.github.com/bootstrap */

/*
audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
*/
