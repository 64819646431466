.frame {
    display: grid;
    // grid-template-rows: 36px 430px 220px; // 688
    grid-template-rows: 5.23% 62.5% 31.9767%; // 688
    // grid-template-columns: 34px 763px 34px; // 832
    grid-template-columns: 4% 91.7% 4%; // 832
    .picture-cover {
        grid-row: 1/4;
        grid-column: 1/4;
        z-index: 3;
        display: flex;
    }
    .frame__framer {
        grid-row: 1/4;
        grid-column: 1/4;
        z-index: 2;
        display: flex;
    }
    .frame__window {
        grid-row: 2/3;
        grid-column: 2/3;
        z-index: 4;
        display: flex;
    }
    .frame__window--cover {
        grid-row: 2/3;
        grid-column: 2/3;
        z-index: 5;
        display: flex;
    }
}

.frame__framer {
    //z-index: 2;
}

// This is just for positioning
.frame__window {
    //z-index: 3;
    //position: absolute;
    //inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    margin-inline: auto;
    //padding: 36px 34px;
}

.frame__window--cover {
    width: 100%;
    height: 100%;
    margin-inline: auto;
    pointer-events: none;
    background-color: #000000;
    transition: all 0.8s;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.frame__window__inner {
    width: 100%;
    height: auto;
    overflow-y: scroll;
    overflow-x:visible;
    margin-inline: auto;
    // Trying to capture the scroll
    // Idea: https://stackoverflow.com/questions/10626503/scroll-an-overflowing-div-by-scrolling-anywhere-on-the-page
}

.frame__window__content {
    width: 100%;
    height: auto;
    > * {
        width: 100% !important;
        height: auto !important;
    }
}

.frame__image {


}

/* Loading ------------------------------------------------------ */

.frame {
    &.moved--finished {
        .frame__window--cover {
            opacity: 1 !important;
        }
    }
    &.screen-loaded {
        .frame__window--cover {
            opacity: 0 !important;
        }
    }
}

/* Screenloader ------------------------------------------------------ */

.ball {
    background-color: $color_pink;
    border-radius: 50%;
    height: 3vw;
    position: absolute;
    width: 3vw;
  }

.ball {
    animation: ballBouncing 0.8s ease-in infinite;
    bottom: 39%;
    transform-origin: bottom;
  }

@keyframes ballBouncing {
    0%,
    100% {
      transform: scale(1.5, 0.5);
    }
    20% {
      transform: scaleY(1.2);
    }
    40%,
    80% {
      transform: translateY(-3rem);
    }
    70% {
      transform: translateY(-4rem);
    }
    90% {
      transform: translateY(0);
    }
  }